<template>
  <section>
    <div class="row mt-4 mt-md-5">
      <div class="col-12 col-md-8 text-center mx-auto" v-if="bannerUrl">
        <a :href="shopUrl">
          <img
            :src="this.bannerUrl"
            class="img-fluid rounded"
            alt="Official Merchandise Banner"
          />
        </a>
      </div>
      <div class="col-12 col-md-8 text-center mx-auto" v-else>
        <router-link :to="{ name: 'shop' }">
          <img
            v-lazy="require('@/assets/img/tienda-bujin.png')"
            class="img-fluid rounded"
            alt="Official Merchandise Banner"
          />
        </router-link>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "EventMerchBanner",
  props: {
    bannerUrl: {
      required: false,
      type: String,
    },
    shopUrl: {
      required: true,
      type: String,
    },
  },
};
</script>

<style scoped></style>
