<template>
  <div class="container-fluid">
    <div
      class="row bujin-seminar-detail-banner d-flex flex-column my-0 pt-lg pb-3 pb-sm-md px-sm"
      :style="eventBanner"
    ></div>
    <div class="row">
      <div class="col-12 col-md-8 mx-auto align-content-center">
        <!--      Mobile-->
        <h4
          class="text-center font-weight-900 mt-4 mt-md-0 mb-4 d-md-none"
          v-if="event.title"
        >
          {{ event.title }}
        </h4>
        <h5
          class="text-justify font-weight-900 mb-5 d-md-none"
          v-if="event.short_description"
        >
          {{ event.short_description }}
        </h5>
        <!--  Other sizes -->
        <h1 class="font-weight-900 mb-5 d-none d-md-block" v-if="event.title">
          {{ event.title }}
        </h1>
        <h3 class="mb-5 d-none d-md-block" v-if="event.short_description">
          {{ event.short_description }}
        </h3>
      </div>
    </div>
    <event-register-button
      :ticket-url="event.ticket_url"
      :price="event.price"
      :currency="event.currency"
      class="mb-4 mb-md-0"
      v-if="!pastDate"
    />
    <section id="event-description">
      <div class="col-12 col-md-8 mx-auto mt-md-5 align-content-center">
        <span
          v-if="event.video_url"
          class="d-none d-md-block embed-responsive embed-responsive-21by9 mb-5"
        >
          <iframe class="embed-responsive-item" :src="event.video_url"></iframe>
        </span>
        <span
          v-if="event.video_url"
          class="d-md-none embed-responsive embed-responsive-16by9 mb-5"
        >
          <iframe class="embed-responsive-item" :src="event.video_url"></iframe>
        </span>
        <p class="font-weight-bold">Description</p>
        <p class="text-justify text-md-left">{{ event.description }}</p>
        <p>
          <span class="font-weight-bold">Event Price: </span>
          <event-price :price="event.price" :currency="event.currency" />
        </p>
        <p>
          <span class="font-weight-bold">Your Local time:</span>
          <event-date :timestamp="event.timestamp" :timezone="'Asia/Tokyo'" />
        </p>
        <p v-if="event.duration">
          <span class="font-weight-bold">Event Duration: </span>
          <span>{{ event.duration }}</span>
        </p>
        <p v-if="event.languages">
          <span class="font-weight-bold">Event Languages: </span>
          <span>{{ event.languages }}</span>
        </p>
        <p v-if="event.certificate_url">
          <span class="font-weight-bold">Certificate download link: </span>
          <span
            ><a :href="event.certificate_url" target="_blank" download="">
              Click here to download your event's certificate
            </a></span
          >
        </p>
        <p v-if="event.promotion">
          <span class="font-weight-bold" v-if="event.promotion_title"
            >{{ event.promotion_title }}:
          </span>
          <span>{{ event.promotion }} ⭐️ </span>
        </p>
        <p class="font-weight-bold" v-if="event.instructions">
          Instructions for Participants
        </p>
        <p v-if="event.instructions">{{ event.instructions }}</p>
      </div>
    </section>

    <event-register-button
      :ticket-url="event.ticket_url"
      :price="event.price"
      :currency="event.currency"
      class="mt-5"
      v-if="!pastDate"
    />
    <event-merch-banner
      :shop-url="event.merchandise_shop_url"
      :banner-url="event.merchandise_banner_url"
      v-if="event.merchandise"
    ></event-merch-banner>

    <event-sponsors />
  </div>
</template>

<script>
import EventsDataSource from "@/EventDataSouce.json";
import EventRegisterButton from "@/views/components/Events/EventRegisterButton";
import EventDate from "@/views/components/Events/EventDate";
import EventMerchBanner from "@/views/components/Events/EventMerchBanner";
import EventSponsors from "@/views/components/Events/EventSponsors";
import EventPrice from "@/views/components/Events/EventPrice";
import dayjs from "dayjs";

export default {
  name: "EventView",
  components: {
    EventSponsors,
    EventMerchBanner,
    EventDate,
    EventRegisterButton,
    EventPrice,
  },
  data() {
    return {
      events: EventsDataSource,
      slug: this.$route.params.slug,
    };
  },
  computed: {
    event() {
      return this.events.find((event) => event.slug === this.slug);
    },
    eventBanner() {
      const url = "url('" + this.event.banner_url + "')";
      return {
        "--bg-image": url,
      };
    },
    pastDate() {
      const now = dayjs();
      const event = dayjs.unix(this.event.timestamp);
      return now.isAfter(event);
    },
  },
};
</script>

<style lang="scss" scoped>
.bujin-seminar-detail-banner {
  background-image: var(--bg-image);
  background-size: cover;
  background-repeat: no-repeat;
  min-height: 130px;
  @media (min-width: 768px) {
    background-size: contain;
    min-height: 650px;
  }
}
</style>
