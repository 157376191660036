<template>
  <section id="sponsors" class="mb-5">
    <div class="row justify-content-center mt-5">
      <div class="col-3 col-sm-6 text-center">
        <img
          v-lazy="require('@/assets/img/presented-by.png')"
          class="img-fluid"
          alt="Presented by Bujin.tv"
        />
      </div>
      <div class="col-3 col-sm-6 text-center">
        <img
          v-lazy="require('@/assets/img/asato-logo.png')"
          class="img-fluid"
          alt="Logo 100 Kata for Karate Day Challenge 2021"
        />
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "EventSponsors",
};
</script>

<style scoped></style>
