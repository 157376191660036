<template>
  <div class="row">
    <div class="col-12 col-md-4 mx-auto text-center">
      <a :href="ticketUrl">
        <button class="ml-0 btn btn-danger w-100" v-if="price > 0">
          Buy Tickets for
          <event-price :price="price" :currency="currency" />
        </button>
        <button class="ml-0 btn btn-danger w-100" v-else>
          Register for this event for FREE!
        </button>
      </a>
    </div>
  </div>
</template>

<script>
import EventPrice from "@/views/components/Events/EventPrice";

export default {
  name: "EventRegisterButton",
  components: { EventPrice },
  props: {
    ticketUrl: {
      required: true,
      type: String,
    },
    price: {
      required: true,
      type: Number,
    },
    currency: {
      required: true,
      type: String,
    },
  },
};
</script>

<style scoped></style>
